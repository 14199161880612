// import React, { Component } from "react";
// import { View, Text, Image, TextInput } from "react-native";
// import * as faceapi from "face-api.js";
// // import { APP_COLOURS } from "../../APP_VARS";
// // import Lottie from "lottie-react";
// // import * as animationData from "./1052-face-id.json";

// export default class FacialRecognition extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {};
// 	}

// 	componentDidMount() {
// 		// console.log(window.user.base64);
// 	}

// 	render() {
// 		return (
// 			<View>
// 				<App
// 					imageUri={this.props.imageUri}
// 					userData={""}
// 					faceMatch={() => this.props.faceMatch(true)}
// 				/>
// 			</View>
// 		);
// 	}
// }

// function App({ imageUri, userData, faceMatch }) {
// 	const [modelsLoaded, setModelsLoaded] = React.useState(false);
// 	const [captureVideo, setCaptureVideo] = React.useState(false);

// 	const [detects, setDetections] = React.useState([]);

// 	const videoRef = React.useRef();
// 	const videoHeight = 480;
// 	const videoWidth = 640;
// 	const canvasRef = React.useRef();

// 	const MODEL_URL = process.env.PUBLIC_URL + "/models";
// 	// alert(MODEL_URL);

// 	// alert(imageUri);

// 	// console.log();

// 	React.useEffect(() => {
// 		const loadModels = async () => {
// 			Promise.all([
// 				faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
// 				faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
// 				faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
// 				faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
// 				faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
// 			]).then(setModelsLoaded(true));

// 			// const MODEL_URL_DISK = "./models";
// 			// alert(MODEL_URL_DISK);
// 			// Promise.all([
// 			// 	faceapi.nets.tinyFaceDetector.loadFromDisk(MODEL_URL_DISK),
// 			// 	faceapi.nets.faceLandmark68Net.loadFromDisk(MODEL_URL_DISK),
// 			// 	faceapi.nets.faceRecognitionNet.loadFromDisk(MODEL_URL_DISK),
// 			// 	faceapi.nets.faceExpressionNet.loadFromDisk(MODEL_URL_DISK),
// 			// 	faceapi.nets.ssdMobilenetv1.loadFromDisk(MODEL_URL_DISK),
// 			// ]).then(setModelsLoaded(true));
// 		};
// 		loadModels();
// 		handleStart();
// 	}, []);

// 	React.useEffect(() => {
// 		const startVideo = () => {
// 			setCaptureVideo(true);
// 			navigator.mediaDevices
// 				.getUserMedia({ video: { width: 300 } })
// 				.then((stream) => {
// 					let video = videoRef.current;
// 					video.srcObject = stream;
// 					video.setAttribute("playsinline", true);
// 					video.play();
// 				})
// 				.catch((err) => {
// 					closeWebcam();
// 					console.error("error:", err);
// 				});
// 		};

// 		startVideo();
// 	}, []);

// 	// const referenceImage = document.getElementById("myImg");

// 	const referenceImage = document.getElementById("image-container");
// 	// let referenceImage = document.getElementById("image-container");
// 	// const referenceImage = document.getElementById("base64Input").value;

// 	const startVideo = () => {
// 		setCaptureVideo(true);
// 		navigator.mediaDevices
// 			.getUserMedia({ video: { width: 300 } })
// 			.then((stream) => {
// 				let video = videoRef.current;
// 				video.srcObject = stream;
// 				video.setAttribute("playsinline", true); // Add playsinline attribute
// 				video.play();
// 			})
// 			.catch((err) => {
// 				closeWebcam();
// 				console.error("error:", err);
// 			});
// 	};

// 	const handleVideoOnPlay = async () => {
// 		setInterval(async () => {
// 			if (
// 				canvasRef &&
// 				canvasRef.current &&
// 				referenceImage instanceof HTMLImageElement
// 			) {
// 				canvasRef.current.innerHTML = faceapi.createCanvas(
// 					videoRef.current,
// 				);
// 				const displaySize = {
// 					width: videoWidth,
// 					height: videoHeight,
// 				};

// 				faceapi.matchDimensions(canvasRef.current, displaySize);

// 				const detections = await faceapi
// 					.detectAllFaces(
// 						videoRef.current,
// 						new faceapi.TinyFaceDetectorOptions(),
// 					)
// 					.withFaceLandmarks()
// 					.withFaceExpressions();

// 				const resizedDetections = faceapi.resizeResults(
// 					detections,
// 					displaySize,
// 				);
// 				// console.log(detections);

// 				setDetections(JSON.stringify(detections));

// 				// referenceImage = document.getElementById("image-container");
// 				const results = await faceapi
// 					.detectAllFaces(referenceImage)
// 					.withFaceLandmarks()
// 					.withFaceDescriptors();
// 				if (!results.length) {
// 					return;
// 				}
// 				const faceMatcher = new faceapi.FaceMatcher(results);
// 				// create FaceMatcher with automatically assigned labels
// 				// from the detection results for the reference image

// 				//
// 				//
// 				const singleResult = await faceapi
// 					.detectSingleFace(videoRef.current)
// 					.withFaceLandmarks()
// 					.withFaceDescriptor();
// 				console.log("singleResult", singleResult);
// 				if (singleResult) {
// 					const bestMatch = faceMatcher.findBestMatch(
// 						singleResult.descriptor,
// 					);
// 					console.log("bestMatch 1", bestMatch);
// 					console.log("bestMatch", bestMatch.toString());
// 					if (bestMatch._label !== "unknown") {
// 						await closeWebcam();
// 						await faceMatch();
// 						window.ReactNativeWebView.postMessage("MATCH_FOUND");
// 					} else {
// 						return;
// 					}
// 				}

// 				canvasRef &&
// 					canvasRef.current &&
// 					canvasRef.current
// 						.getContext("2d")
// 						.clearRect(0, 0, videoWidth, videoHeight);
// 				canvasRef &&
// 					canvasRef.current &&
// 					faceapi.draw.drawDetections(
// 						canvasRef.current,
// 						resizedDetections,
// 					);
// 			}
// 		}, 300);
// 	};

// 	const closeWebcam = () => {
// 		videoRef.current.pause();
// 		videoRef.current.srcObject.getTracks()[0].stop();
// 		setCaptureVideo(false);
// 	};

// 	const handleStart = () => {
// 		setTimeout(() => {
// 			startVideo();
// 		}, 500);
// 	};

// 	return (
// 		<div
// 			style={{
// 				maxWidth: 500,
// 				maxHeight: 500,
// 				// width: 500,
// 				// height: 500,
// 				justifyContent: "center",
// 				alignItems: "center",
// 				// flex: 1,
// 				backgroundColor: "yellow",
// 			}}>
// 			{/* <p
// 				style={{
// 					position: "absolute",
// 					fontSize: 8,
// 				}}>
// 				{detects}
// 			</p> */}

// 			{/* <View
// 				style={{
// 					width: "100%",
// 					flexDirection: "row",
// 					backgroundColor: modelsLoaded ? "blue" : "gray",
// 					opacity: 0.7,
// 					padding: 10,
// 					borderRadius: 5,
// 				}}>
// 				<View
// 					style={{
// 						width: 100,
// 						height: 100,
// 						borderRadius: 50,
// 						overflow: "hidden",
// 						justifyContent: "center",
// 						alignItems: "center",
// 					}}>
// 					<img
// 						style={{
// 							// display: "none",
// 							width: 100,
// 							height: 100,
// 						}}
// 						id='image-container'
// 					/>
// 					<img
// 						id='myImg'
// 						src={imageUri}
// 						style={
// 							{
// 								// display: "none",
// 							}
// 						}
// 						// onLoad={startVideo}
// 					/>
// 					<Image
// 						source={imageUri}
// 						style={{
// 							width: 100,
// 							height: 100,
// 							borderRadius: 50,
// 							resizeMode: "cover",
// 						}}
// 					/>
// 				</View>
// 			</View> */}

// 			<div>
// 				<div
// 					style={{
// 						display: "flex",
// 						justifyContent: "center",
// 						padding: "10px",
// 						backgroundColor: "yellow",
// 					}}>
// 					<video
// 						ref={videoRef}
// 						height={videoHeight}
// 						width={videoWidth}
// 						onPlay={handleVideoOnPlay}
// 						style={{ borderRadius: "10px" }}
// 					/>
// 					<canvas ref={canvasRef} style={{ position: "absolute" }} />
// 				</div>
// 			</div>

// 			<img
// 				id='myImg'
// 				src={imageUri}
// 				style={{
// 					width: 100,
// 					height: 100,
// 					// display: "none",
// 				}}
// 			/>

// 			<img
// 				id='myImghjbvjh'
// 				src={window.user.base64}
// 				style={{
// 					// display: "none",
// 					width: 100,
// 					height: 100,
// 				}}
// 				// onLoad={startVideo}
// 			/>

// 			<Text
// 				style={{
// 					fontSize: 20,
// 				}}>
// 				{imageUri.slice(0, 90)}
// 			</Text>
// 		</div>
// 	);
// }

import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import * as faceapi from "face-api.js";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		console.log(this.props.imageUri);
	}

	render() {
		return (
			<View>
				<App
					imageUri={this.props.imageUri}
					userData={this.props.userData}
					faceMatchDetected={() => this.props.faceMatchDetected(true)}
				/>
			</View>
		);
	}
}

function App({ imageUri, userData, faceMatchDetected }) {
	const [modelsLoaded, setModelsLoaded] = React.useState(false);
	const [captureVideo, setCaptureVideo] = React.useState(false);

	const videoRef = React.useRef();
	const videoHeight = 480;
	const videoWidth = window.innerWidth;
	const canvasRef = React.useRef();

	// React.useEffect(() => {
	// 	const loadModels = async () => {
	// 		const MODEL_URL = process.env.PUBLIC_URL + "/models";

	// 		Promise.all([
	// 			faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
	// 			faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
	// 			faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
	// 			faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
	// 			faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
	// 		]).then(setModelsLoaded(true));
	// 	};
	// 	loadModels();
	// 	handleStart();
	// }, []);

	React.useEffect(() => {
		const loadModels = async () => {
			const MODEL_URL = process.env.PUBLIC_URL + "/models";

			const promis_res = await Promise.all([
				faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
				faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
				faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
				faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
				faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
			]);

			console.log(promis_res);
			console.log(
				"faceapi.nets.tinyFaceDetector.isLoaded()",
				faceapi.nets.tinyFaceDetector.isLoaded,
			);

			await handleStart();
		};
		loadModels();
	}, []);

	const referenceImage = document.getElementById("myImg");

	const startVideo = () => {
		setCaptureVideo(true);
		navigator.mediaDevices
			.getUserMedia({ video: { width: window.innerWidth } })
			.then((stream) => {
				let video = videoRef.current;
				video.srcObject = stream;
				video.playsinline = true;
				video.play();
			})
			.catch((err) => {
				closeWebcam();
				console.error("error:", err);
			});
	};

	const handleVideoOnPlay = async () => {
		setInterval(async () => {
			if (
				canvasRef &&
				canvasRef.current &&
				referenceImage instanceof HTMLImageElement
			) {
				canvasRef.current.innerHTML = faceapi.createCanvas(
					videoRef.current,
				);
				const displaySize = {
					width: videoWidth,
					height: videoHeight,
				};

				faceapi.matchDimensions(canvasRef.current, displaySize);

				const detections = await faceapi
					.detectAllFaces(
						videoRef.current,
						new faceapi.TinyFaceDetectorOptions(),
					)
					.withFaceLandmarks()
					.withFaceExpressions();

				const resizedDetections = faceapi.resizeResults(
					detections,
					displaySize,
				);

				canvasRef &&
					canvasRef.current &&
					canvasRef.current
						.getContext("2d")
						.clearRect(0, 0, videoWidth, videoHeight);
				canvasRef &&
					canvasRef.current &&
					faceapi.draw.drawDetections(
						canvasRef.current,
						resizedDetections,
					);

				//
				//

				const results = await faceapi
					.detectAllFaces(referenceImage)
					.withFaceLandmarks()
					.withFaceDescriptors();
				if (!results.length) {
					return;
				}
				const faceMatcher = new faceapi.FaceMatcher(results);
				// create FaceMatcher with automatically assigned labels
				// from the detection results for the reference image

				//
				// console.log(faceMatcher);
				// return;
				//
				const singleResult = await faceapi
					.detectSingleFace(videoRef.current)
					.withFaceLandmarks()
					.withFaceDescriptor();
				// console.log("singleResult", singleResult);

				// console.log(singleResult.detection);

				if (singleResult) {
					const bestMatch = faceMatcher.findBestMatch(
						singleResult.descriptor,
					);
					// console.log(
					// 	"bestMatch",
					// 	bestMatch.toString(),
					// 	bestMatch.distance,
					// );

					// Calculate the match score as a percentage
					const matchScore = (1 - bestMatch.distance) * 100;

					// Check if the match score is greater than or equal to 80%
					if (matchScore >= 50) {
						await closeWebcam();
						await faceMatchDetected();

						console.log("match", matchScore);
					} else {
						console.log("False match", matchScore);
						return;
					}
				}

				//
				//
				//
			}
		}, 500);
	};

	const closeWebcam = () => {
		videoRef.current.pause();
		videoRef.current.srcObject.getTracks()[0].stop();
		setCaptureVideo(false);
	};

	const handleStart = () => {
		return startVideo();
	};

	return (
		<div
			style={{
				// maxWidth: 500,
				// maxHeight: 500,
				width: "100%",

				// justifyContent: "center",
				alignItems: "center",
				// backgroundColor: "red",
			}}>
			<img
				id='myImg'
				src={imageUri}
				style={{
					width: 100,
					height: 50,
					display: "none",
				}}
			/>

			<div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						padding: "10px",
					}}>
					<video
						ref={videoRef}
						height={videoHeight}
						width={videoWidth}
						onPlay={handleVideoOnPlay}
						style={{ borderRadius: "10px" }}
						playsInline
						autoPlay
						muted
					/>
					<canvas ref={canvasRef} style={{ position: "absolute" }} />
				</div>
			</div>

			<View
				style={{
					width: "100%",
					flexDirection: "row",
					backgroundColor: "gray",
					opacity: 0,
					padding: 10,
					borderRadius: 5,
				}}>
				<View
					style={{
						width: 100,
						height: 100,
						borderRadius: 50,
						overflow: "hidden",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<img
						id='myImg'
						src={imageUri}
						style={{
							display: "none",
						}}
						// onLoad={startVideo}
					/>
					<Image
						source={imageUri}
						style={{
							width: 100,
							height: 100,
							borderRadius: 50,
							resizeMode: "cover",
						}}
					/>
				</View>
			</View>
		</div>
	);
}
