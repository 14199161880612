import React, { Component } from "react";
import { View, Text } from "react-native";

import FacialRecognition from "./FacialRecognition";

export default class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			base64string: window.user.base64,
			load: false,
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				load: true,
			});
		}, 500);
	}

	componentDidUpdate() {
		console.log(window.BASE64_STRING);

		// alert(window.user.base64.slice(0, 90));
	}

	handleInputChange = (event) => {};

	reloadC = () => {
		// setTimeout(() => {
		// 	this.setState({
		// 		load: true,
		// 	});
		// }, 3000);
	};

	render() {
		let d = this.state.d;
		return (
			<View
				style={{
					width: window.innerWidth,
					height: window.innerHeight,
					// backgroundColor: "green",
					// justifyContent: "center",
					alignItems: "center",
				}}>
				{/* <input
					type='text'
					id='base64Input'
					placeholder='Paste base64 string '
					onChange={this.handleInputChange}
				/> */}
				{/* {this.state.load && window.user.base64 !== "" ? ( */}
				<FacialRecognition
					userData={d}
					imageUri={window.user.base64}
					faceMatchDetected={() => {
						var payload = {
							status: "access valid",
						};
						window.ReactNativeWebView.postMessage(
							JSON.stringify(payload),
						);
					}}
				/>
				{/* ) : null} */}

				{/* <img
					id='myImg'
					src={this.state.b64}
					style={{
						// display: "none",
						width: 100,
						height: 100,
					}}
					// onLoad={startVideo}
				/>

				<Text
					style={{
						fontSize: 20,
					}}>
					{window.user.base64.slice(0, 90)}
				</Text> */}
			</View>
		);
	}
}
